import restaurant from "./images/rest.jpg";
import biserica from "./images/bisp.jpg";
import img_card from "./images/picioruse.jpg";
import imgheadermb from "./images/f21m.jpg";
import imgheader from "./images/f2m.jpg";
import imgheadermiini from "./images/f4.jpg";
import imgheadermiinimb from "./images/f4.jpg";
import logo from './images/stea.png'



const data = {
    introData: [{
        copilul: "Victoraș",
        familia: "familia Verdeș",
        logo: logo,
        tata: "Constantin",
        mama: "Valeria",
        data: "8 August 2023",
        data_confirmare: "20 iulie 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "valeriaterenti97@gmail.com", 
       tel: "+37369443675",
       phone: "tel:+37369443675",
       viber: "viber://chat?number=%2B37369443675",
       whatsapp: "https://wa.me/+37369443675",
       messenger: "https://www.messenger.com/t/Valeria.terentiev",
       tel1: "+4xxx",
       phone1: "tel:+4xxx",
       viber1: "viber://chat?number=%2B4xxx",
       whatsapp1: "https://wa.me/+4xxxx",
       messenger1: "https://www.messenger.com/t/",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Biserica",
            name: "Sf. Alexei",
            data: "8 august 2023, marți, ora 14:00",
            adress: "s. Pohrebea, r-l. Dubăsari",
            harta: "https://goo.gl/maps/FMWmk8cjwRHAqrpc8",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Mayorall",
            data: "8 august 2023, marți, ora 16:00",
            adress: "s. Doroţcaia, r-l. Dubăsari",
            harta: "https://goo.gl/maps/KDKgAD8Kn9PaS2BA9",
            iframe: ""
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar al lui Dumnezeu, un motiv de a crede în minuni, este pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;